<template>
  <v-container>
    <v-row>
      You are logged in as {{ user.displayName }}
      <v-btn
        dark
        rounded
        small
        class="ml-5"
        @click="logOut"
      >
        Log out
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
  import i18n from '../i18n'
  export default {
    name: 'Account',
    data () {
      return {
        photo: '',
        userId: '',
        name: '',
        email: '',
        user: {},
      }
    },
    created () {

    },
    mounted () {
      this.user = this.$firebaseAuth.currentUser
      if (!this.user) {
        this.$router.push('/' + i18n.locale + '/user/login')
      } else {
        this.name = this.user.displayName
        this.email = this.user.email
        this.photo = this.user.photoURL
        this.userId = this.user.uid
      }
    },
    methods: {
      logOut () {
        this.$firebaseAuth.signOut()
        this.$router.push('/' + i18n.locale + '/user/login')
      },
    },
  }
</script>
